import React from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import logo from './images/resize_17664_company_logo.png'; 
class table extends React.Component {
  constructor(){
    super();
    this.state = {
        items: [],
        isLoaded: false,
        tag_name:"",
        previous_id:"",
    };
    // fix the this value
    this.getroom = this.getroom.bind(this);
    this.gettag = this.gettag.bind(this);
    this.getMypost = this.getMypost.bind(this);
  }

  componentWillMount() {
    this.getroom()
  }
  changetopic_id = (e) =>{
    this.setState({previous_id: e.target.value-1});
  }
  changetag = (e) =>{
    this.setState({tag_name: e.target.value});
  }
  getMypost() {
    var url = new URL("https://pantip.com/api/forum-service/forum/tag_topic?"),
    params = {tag_name:this.state.tag_name, previous_id: this.state.previous_id}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    fetch(url, {
    headers: {
    Accept: "application/json, text/plain, */*",
    Ptauthorize: "Basic dGVzdGVyOnRlc3Rlcg==",
    "Sec-Fetch-Dest": "empty",
    "Sec-Fetch-Mode": "cors",
    "Sec-Fetch-Site": "same-origin"
   }}).then(res => res.json())
      .then(result => {
        this.setState({
          isLoaded: true,
          items: result.data
        });
      });
    }

  getroom() {
    fetch("https://pantip.com/api/forum-service/forum/room_topic_recommend?room=sinthorn&limit=100", {
        headers: {
          Accept: "application/json, text/plain, */*",
          Ptauthorize: "Basic dGVzdGVyOnRlc3Rlcg==",
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-origin"
        }
      }).then(res => res.json())
      .then(result => {
        this.setState({
          isLoaded: true,
          items: result.data
        });
      });
    }

   

    gettag() {
        fetch("https://pantip.com/api/forum-service/forum/tag_topic?tag_name=ศาลาคนโสด&limit=100", {
            headers: {
              Accept: "application/json, text/plain, */*",
              Ptauthorize: "Basic dGVzdGVyOnRlc3Rlcg==",
              "Sec-Fetch-Dest": "empty",
              "Sec-Fetch-Mode": "cors",
              "Sec-Fetch-Site": "same-origin"
            }
          }).then(res => res.json())
          .then(result => {
            this.setState({
              isLoaded: true,
              items: result.data
            });
          });
        }

  render() {
    const { items } = this.state;
    console.log(items)

    
    return (
	
      <div>
 
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href="#home">
        <img
          alt=""
          src={logo}
          width="80"
          height="50 "
          className="d-inline-block align-top"
        />{' '}
      Watanikarn Digital Solution 
      </Navbar.Brand>
    </Container>
  </Navbar>
  <Row className="align-items-center">
    <Col sm={3} className="my-1">
      
    <Form.Control id="inlineFormInputName" placeholder="topic id" onChange={this.changetopic_id} />
    </Col>
    <Col sm={3} className="my-1">
     
      <InputGroup>
        <InputGroup.Text>tag</InputGroup.Text>
        <FormControl id="inlineFormInputGroupUsername" placeholder="tag name"  onChange={this.changetag} />
      </InputGroup>
    </Col>
    
    <Col xs="auto" className="my-1">
      <Button onClick={this.getMypost}>Serach</Button>
    </Col>
  </Row>

          <hr/>
          {' '}<Button variant="primary"onClick={this.gettag}>Get Tag</Button>{' '}
          <Button variant="primary"onClick={this.getroom}>Get Room</Button>
          
          <br/>
          <hr/>
          
  <Table striped bordered hover>

  <thead>
    <tr>
      <th>id</th>
      <th>Topic name</th>
      <th>view</th>
      <th>time</th>
      <th>comments</th>
      <th>vote</th>
    </tr>
  </thead>
  <tbody>
          {items.map(item => (
          <tr key={item.title}>
          <td>{ item.topic_id }</td>
          <td>{ item.title }</td>
          <td>{ item.views_count }</td>
          <td>{ item.created_time }</td>
          <td>{ item.comments_count }</td>
          <td>{ item.votes_count }</td>
            
            </tr>
            
          ))}
  </tbody> 
  

  </Table>   
      </div>  
  
	
  );
          
  }
}

export default table;