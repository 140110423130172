import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Container from 'react-bootstrap/Container'
import table from './Table/table'
import prb from './Table/prb'
import pt from './Table/pantippro'


const Routing = () => {
  return(
    <Container>
    <Router>
      
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/pantip" component={table} />
        <Route path="/prb" component={prb} />
        <Route path="/pt" component={pt} />
        {/* <Route path="/login" component={google} /> */}
       
      </Switch>
   
    </Router>
    </Container>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);