import React from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import logo from './images/resize_17664_company_logo.png'; 
class prb extends React.Component {
  constructor(){
    super();
    this.state = {
        items: [],
        isLoaded: false,
        tag_name:"",
        view:"",
        path:"",
        topic_id:""
    };
    // fix the this value
    this.getroom = this.getroom.bind(this);
    this.gettag = this.gettag.bind(this);
    this.getMypost = this.getMypost.bind(this);
  }

  componentWillMount() {
    this.getroom()
  }
  view = (e) =>{
    this.setState({view: e.target.value});
  }
  topic_id = (e) =>{
    this.setState({topic_id: e.target.value});
  }
  path = (e) =>{
    this.setState({path: e.target.value});
  }
  getMypost() {
    var myHeaders = new Headers();
    myHeaders.append("authority", "frontend-api.prbangkok.com");
    myHeaders.append("accept", "application/json, text/plain, */*");
    myHeaders.append("user-agent", "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1");
    myHeaders.append("content-type", "application/json;charset=UTF-8");
    myHeaders.append("origin", "https://www.prbangkok.com");
    myHeaders.append("sec-fetch-site", "same-site");
    myHeaders.append("sec-fetch-mode", "cors");
    myHeaders.append("sec-fetch-dest", "empty");
    myHeaders.append("referer", "https://www.prbangkok.com/");
    myHeaders.append("accept-language", "en-US,en;q=0.9");
    
    var raw = `{\"id\":${this.state.topic_id},\"view\":${this.state.view}}`;
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://frontend-api.prbangkok.com/api/v1/shop/news/add-view-media", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    }

  getroom() {
    for (let i = 0; i < this.state.view; i++) {
      var myHeaders = new Headers();
      myHeaders.append("authority", "www.maepeng.com");
      myHeaders.append("accept", "*/*");
      myHeaders.append("accept-language", "en-US,en;q=0.9");
      myHeaders.append("content-type", "application/x-www-form-urlencoded");
      myHeaders.append("origin", "https://www.ch7.com");
      myHeaders.append("referer", "https://www.ch7.com/");
      myHeaders.append("sec-fetch-dest", "empty");
      myHeaders.append("sec-fetch-mode", "cors");
      myHeaders.append("sec-fetch-site", "cross-site");
      myHeaders.append("user-agent", "Mozilla/5.0 (iPad; CPU OS 13_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1");
      
      var urlencoded = new URLSearchParams();
      urlencoded.append("entry", "570668");
      urlencoded.append("site", "ch7");
      urlencoded.append("action", "view");
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      
      fetch("https://www.maepeng.com/api/v1/Ms/ptk", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
}
    
   
   
    gettag() {
        var myHeaders = new Headers();
        myHeaders.append("authority", "frontend-api.prbangkok.com");
        myHeaders.append("accept", "application/json, text/plain, */*");
        myHeaders.append("user-agent", "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1");
        myHeaders.append("content-type", "application/json;charset=UTF-8");
        myHeaders.append("origin", "https://www.prbangkok.com");
        myHeaders.append("sec-fetch-site", "same-site");
        myHeaders.append("sec-fetch-mode", "cors");
        myHeaders.append("sec-fetch-dest", "empty");
        myHeaders.append("referer", "https://www.prbangkok.com/");
        myHeaders.append("accept-language", "en-US,en;q=0.9");
        
        var raw = `{\"id\":${this.state.topic_id},\"view\":${this.state.view}}`;
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://frontend-api.prbangkok.com/api/v1/shop/news/add-view", requestOptions)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }

  render() {
    const { items } = this.state;
    console.log(items)
    
    return (
	
      <div>
 
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href="#home">
        <img
          alt=""
          src={logo}
          width="80"
          height="50 "
          className="d-inline-block align-top"
        />{' '}
      Watanikarn Digital Solution 
      </Navbar.Brand>
    </Container>
  </Navbar>
  <hr/>
  <Row className="align-items-center">
    <Col sm={3} className="my-1">
      
    <Form.Control id="inlineFormInputName" placeholder="view" onChange={this.view} />
    </Col>
    <Col sm={3} className="my-1">
     
      <InputGroup>
        <InputGroup.Text>PostId</InputGroup.Text>
        <FormControl id="inlineFormInputGroupUsername" placeholder="tag name"  onChange={this.topic_id} />
      </InputGroup>
      <hr/>
      <InputGroup>
        <InputGroup.Text>Path</InputGroup.Text>
        <FormControl id="inlineFormInputGroupUsername" placeholder="Path"  onChange={this.path} />
      </InputGroup>
    </Col>
    
    <Col xs="auto" className="my-1">
  
    </Col>
  </Row>
   
          <hr/>
          <Button onClick={this.getMypost}>เพิ่มยอดวิวบทความVDO</Button>{' '}
          <hr/>
          {' '}<Button variant="primary"onClick={this.gettag}>เพิ่มวิวบทความ</Button>{' '}
          <hr/>
          {' '}<Button variant="primary"onClick={this.getroom}>เพิ่มยอดวิว</Button>
    
          <br/>
          <hr/>
  <Table striped bordered hover>
  <thead>
    <tr>
      <th>id</th>
      <th>Topic name</th>
      <th>view</th>
      <th>time</th>
      <th>comments</th>
    </tr>
  </thead>
  <tbody>
          {items.map(item => (
            <tr key={item.title}>
          <td>{ item.topic_id }</td>
          <td>{ item.title }</td>
          <td>{ item.views_count }</td>
          <td>{ item.created_time }</td>
          <td>{ item.comments_count }</td>
            </tr>
          ))}
  </tbody> 
  </Table>   
      </div>  
  
	
  );
          
  }
}

export default prb;